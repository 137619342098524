import { Button, Container, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#3f7d9c",
    minHeight: "100vh",
    padding: "50px",
    display: "flex"
  },
  img: {
    // margin: "auto",
    // display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none"
  }
  },
  text: {
    marginTop: "100px"
  },
  mainFont: {
    color: "#cff1f2",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    marginBottom: "10px",
    lineHeight: "75px",
    fontSize: "75px",
    fontWeight: 900
  },
  subFont: {
    color: "#cff1f2",
    fontWeight: 900,
    fontSize: "25px",
    fontFamily: "Montserrat",
    marginBottom: "20px",
    lineHeight: "35px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px"
  }
  },
  button: {
    backgroundColor: "#fff",
    marginTop: "25px",
    color: "#3f7d9c",
    lineHeight: "20px",
    fontSize: "14px",
    cursor: "pointer",
    height: "40px"
  },
}))
  ;

const PageNotFound: React.FC<any> = ({ theme }) => {
  const classes = useStyles(theme);
  return (
    <Grid
      container
      spacing={0}
      className={classes.container}
      alignItems="center"
      direction="row"
      justify="center"
    >
      <Grid className={classes.text} item xs={12} sm={12} md={4} lg={4}>
        <Typography className={classes.mainFont}>404</Typography>
        <Typography className={classes.subFont}>What you're "Seq-ing" isn't here.</Typography>
        <a href="https://viz.fredhutch.org" style={{ textDecoration: "none" }}>
          <Button variant="contained" className={classes.button}>Return Home</Button>
        </a>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={7}>
      <img className={classes.img} src="../img/404withClouds.jpg" alt="404" />
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
